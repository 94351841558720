import * as XLSX from 'xlsx/xlsx';
import crypto from 'crypto-js';

export function generateRandomString(length) {
  if (length < 0) {
    return 0;
  }
  const charset = '-';
  let randomString = '';

  for (let i = 0; i < length; i += 1) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    randomString += charset.charAt(randomIndex);
  }

  return randomString;
}

export function removeEmoji(str) {
  return str
    .replace(/[\u{1F600}-\u{1F64F}]/gu, '') // Emoticons
    .replace(/[\u{1F300}-\u{1F5FF}]/gu, '') // Miscellaneous Symbols and Pictographs
    .replace(/[\u{1F680}-\u{1F6FF}]/gu, '') // Transport and Map Symbols
    .replace(/[\u{1F700}-\u{1F77F}]/gu, '') // Alchemical Symbols
    .replace(/[\u{1F780}-\u{1F7FF}]/gu, '') // Geometric Shapes Extended
    .replace(/[\u{1F800}-\u{1F8FF}]/gu, '') // Supplemental Arrows-C
    .replace(/[\u{1F900}-\u{1F9FF}]/gu, '') // Supplemental Symbols and Pictographs
    .replace(/[\u{1FA00}-\u{1FA6F}]/gu, '') // Chess Symbols
    .replace(/[\u{1FA70}-\u{1FAFF}]/gu, '') // Symbols and Pictographs Extended-A
    .replace(/[\u{2600}-\u{26FF}]/gu, '') // Miscellaneous Symbols
    .replace(/[\u{2700}-\u{27BF}]/gu, '') // Dingbats
    .replace(/[\u{FE00}-\u{FE0F}]/gu, '') // Variation Selectors
    .replace(/[\u{1F900}-\u{1F9FF}]/gu, '') // Supplemental Symbols and Pictographs
    .replace(/[\u{1F680}-\u{1F6FF}]/gu, '') // Transport and Map Symbols
    .replace(/[\u{1F700}-\u{1F77F}]/gu, '') // Alchemical Symbols
    .replace(/[\u{1F780}-\u{1F7FF}]/gu, '') // Geometric Shapes Extended
    .replace(/[\u{1F800}-\u{1F8FF}]/gu, '') // Supplemental Arrows-C
    .replace(/[\u{1F900}-\u{1F9FF}]/gu, '') // Supplemental Symbols and Pictographs
    .replace(/[\u{1FA00}-\u{1FA6F}]/gu, '') // Chess Symbols
    .replace(/[\u{1FA70}-\u{1FAFF}]/gu, '') // Symbols and Pictographs Extended-A
    .replace(/[\u{1F900}-\u{1F9FF}]/gu, '') // Supplemental Symbols and Pictographs
    .replace(/[\u{1FA00}-\u{1FA6F}]/gu, '') // Chess Symbols
    .replace(/[\u{1FA70}-\u{1FAFF}]/gu, '') // Symbols and Pictographs Extended-A
    .trim();
}

export function shortTitleCustom(title, minLength, maxLength) {
  if (!title || title.length < minLength) {
    const randomPart = generateRandomString(maxLength - title.length);
    return title ? `${title}${randomPart}` : randomPart;
  }

  if (title.length > maxLength) {
    return title.substring(0, maxLength);
  }

  return title;
}

export function getSizeInBytes(obj) {
  // Convert object to JSON string and calculate its byte length
  return new Blob([JSON.stringify(obj)]).size;
}

/**
 * Util delay function
 * @param {number} ms
 * @param {AbortSignal} signal
 * @return {Promise}
 */
export function delayFunc(ms, signal = null) {
  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(resolve, ms);
    if (signal) {
      if (signal?.aborted) {
        clearTimeout(timeoutId);
        reject(new Error('Operation canceled'));
        return;
      }
      signal?.addEventListener('abort', () => {
        clearTimeout(timeoutId);
        reject(new Error('Operation canceled'));
      });
    }
  });
}

/**
 * Random in range
 * @param {*} delay
 * @return {number}
 */
export function randomInRange({ from, to }) {
  return Math.floor(Math.random() * (to - from + 1)) + from;
}

export function parseJSON(data, defaultValue = {}) {
  try {
    if (!data) {
      return defaultValue;
    }
    return JSON.parse(data);
  } catch (error) {
    return defaultValue;
  }
}

/**
 *  Sum array with items is object with attribute + filter
 * @param {Array} array
 * @param {string} attributeName
 * @param {Function} filterCondition
 * @return {number}
 */
export function sumWith(array = [], attributeName = '', filterCondition = () => {}) {
  return array?.filter(filterCondition)?.reduce((res, item) => {
    let value = 0;
    try {
      value = item[attributeName] || 0;
    } catch (error) {
      value = 0;
    }
    return res + value;
  }, 0);
}
export const exportToExcel = (data, fileName) => {
  // Create a new workbook and add a worksheet
  const ws = XLSX.utils.json_to_sheet(data);

  // Add data with hyperlinks to the worksheet
  data.forEach((item, rowIndex) => {
    Object.values(item).forEach((value, colIndex) => {
      const cellAddress = XLSX.utils.encode_cell({ c: colIndex, r: rowIndex + 1 });
      if (typeof value === 'object' && value !== null && value?.url) {
        ws[cellAddress] = {
          v: value?.text,
          l: {
            Target: value?.url,
            Tooltip: value?.tooltip || value?.url,
          },
        };
      } else {
        ws[cellAddress] = { v: value };
      }
    });
  });

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  // Write the workbook to a binary array buffer
  const buf = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

  // Create a Blob from the buffer
  const blob = new Blob([buf], { type: 'application/octet-stream' });

  // Create a link element and trigger the download
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `${fileName}.xlsx`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};

export function compareVersions(version1, version2) {
  // Split the version strings into major, minor, and patch components
  const [major1, minor1, patch1] = version1.split('.').map(Number);
  const [major2, minor2, patch2] = version2.split('.').map(Number);

  // Compare major versions
  if (major1 > major2) return 1;
  if (major1 < major2) return -1;

  // Compare minor versions
  if (minor1 > minor2) return 1;
  if (minor1 < minor2) return -1;

  // Compare patch versions
  if (patch1 > patch2) return 1;
  if (patch1 < patch2) return -1;

  // Versions are equal
  return 0;
}

export function convertToMD5(input) {
  // Convert the input number to a string
  const inputString = input.toString();

  // Create an MD5 hash of the input string
  return crypto.MD5(inputString).toString();
}

export function generateWaterfallId(length = 11) {
  const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let result = '';

  for (let i = 0; i < length; i += 1) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters[randomIndex];
  }

  return result;
}

export function convertStoryIdToId(storyId) {
  try {
    // Decode the Base64 string
    const decodedString = atob(storyId);

    // Extract the number
    return decodedString.split(':')[3];
  } catch (error) {
    return null;
  }
}
export function convertStoryIdToFeedBackId(storyId) {
  try {
    // Step 1: Decode the input string
    const decodedString = atob(storyId);

    // Step 2: Extract the number part from the decoded string
    const numberPart = decodedString.split(':')[3];

    // Step 3: Create the new string in the format 'feedback:<number>'
    const newString = `feedback:${numberPart}`;

    // Step 4: Encode the new string to Base64
    return btoa(newString);
  } catch (error) {
    return null;
  }
}

export function convertIdToFeedBackId(id) {
  try {
    // Concatenate the string with the specific format used in the storyId
    const stringToEncode = `feedback:${id}`;

    // Encode the string to Base64
    const encodedString = btoa(stringToEncode);

    return encodedString;
  } catch (error) {
    return null;
  }
}

export function convertFeedBackToId(feedback) {
  try {
    // Concatenate the string with the specific format used in the storyId
    const encodedString = atob(feedback);

    return encodedString?.split('feedback:')[1];
  } catch (error) {
    return null;
  }
}

export function detectJobsFormat(str) {
  const pattern = /^jobs-\d+$/;
  return pattern.test(str);
}

export function extractId(url) {
  if (!url) {
    return null;
  }
  const storyFbidMatch = url.match(/story_fbid=(\w+)/);
  const permalinkIdMatch = url.match(/\/permalink\/(\d+)/);

  if (storyFbidMatch) {
    return storyFbidMatch[1];
  }
  if (permalinkIdMatch) {
    return permalinkIdMatch[1];
  }
  return null;
}

export function getCheckpointListConfig(checkpointMessages) {
  try {
    if (!checkpointMessages) {
      return null;
    }

    const list = parseJSON(checkpointMessages);

    if (!Array.isArray(list) && !list?.every((item) => typeof item === 'number', typeof item === 'string')) {
      return null;
    }

    return list;
  } catch (error) {
    console.log('getCheckpointListConfig', error);
    return null;
  }
}

export function getLastOnline(lastOnlineDate) {
  const lastOnline = new Date(lastOnlineDate);

  const rightNow = new Date();

  const differenceInMillis = rightNow - lastOnline;

  const differenceInDays = differenceInMillis / (1000 * 60 * 60 * 24);

  // Return the difference in days as a float with precision allowing 0.1, 0.2, ..., 0.9
  return Math.round(differenceInDays * 10) / 10;
}

export function extractUID(input) {
  try {
    return input
      ?.split('\n')
      ?.map((item) => item?.trim())
      ?.map((item) => item)
      ?.filter((item) => {
        return /^\d+$/.test(item);
      });
  } catch (error) {
    return [];
  }
}
